import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import bg from "./images/hero.png";
import {Footer} from "./components/footer";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import {Home} from "./pages/home";
import {NoPage} from "./pages/no-page";
import {Enroll} from "./pages/enroll";
import {Authenticate} from "./pages/authenticate";
import {useEffect, useRef, useState} from "react";


function App() {
    const [config, setConfig] = useState({});
    const mounted = useRef();
    useEffect(() => {
    if (!mounted.current) {
        fetch('/configurations')
            .then(res => res.json())
            .then(config => {
              setConfig(config)
            })
        }
      mounted.current = true;
  }, []);

  return (

    <BrowserRouter className="App">
      <div className="row" style={{margin: 0}}>
        <div className="col-6 container">
            <div className="row">
                <Routes>
                  <Route path="/" element={<Home />} />
                  <Route path="/enroll" element={<Enroll config={config} />} />
                  <Route path="/authenticate" element={<Authenticate config={config} />} />
                  <Route path="*" element={<NoPage />} />
                </Routes>
                <Footer></Footer>
            </div>
        </div>
        <div className="col-lg-6 col-md-12 pb-0 mb-0 pe-0 ps-0 mt-0 order-lg-2 order-1 background_image" style={{
                  backgroundImage: `url(${bg})`,
                  backgroundSize: "cover",
                  position: "relative",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "100% 93%",
                }}>

        </div>
      </div>
    </BrowserRouter>
  );
}

export default App;
