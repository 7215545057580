import styles from "./login-option.module.css";


const LoginOptions = ({ img, text }) => {
  return (
    <div className={styles["login-option"]}>
      <div className=" d-flex align-items-center">
        <div className="icon_bg d-flex align-items-center justify-content-center">
          <img alt="" src={img} width="16" />
        </div>
        <div className="ms-3 text"> {text}</div>
      </div>
    </div>
  );
};

export default LoginOptions;
