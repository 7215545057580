import Axios from "axios";

const axios = Axios.create({
    baseURL: 'http://127.0.0.1:8000/api/v1/',
    headers: {"Content-Type": "application/json"}
});

export class demosApi {
    static invite(enroll) {
        return axios
            .post("https://conductor.bdika.anonybit.io/api/v1/auth/invite",enroll)
            .then(response => response.data)
    }
}