// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".authenticate_authenticate__ls7MV {\n\t\n}\n\n.authenticate_radio-btn__1qKth {\n    width: 20px!important;\n}", "",{"version":3,"sources":["webpack://./src/pages/authenticate/authenticate.module.css"],"names":[],"mappings":"AAAA;;AAEA;;AAEA;IACI,qBAAqB;AACzB","sourcesContent":[".authenticate {\n\t\n}\n\n.radio-btn {\n    width: 20px!important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"authenticate": "authenticate_authenticate__ls7MV",
	"radio-btn": "authenticate_radio-btn__1qKth"
};
export default ___CSS_LOADER_EXPORT___;
