import styles from "./footer.module.css";

export const Footer = (props) => {
  return <div className={styles.footer}>
      <div className="container">
        <div className="row " style={{display: "inline"}}>
            <a style={{color: "black", marginRight: 10, textDecoration: "unset"}} href="https://anonybit.io/terms"> Terms &amp; Conditions</a>
            <a style={{color: "black", marginRight: 10, textDecoration: "unset"}} href="https://anonybit.io/privacy-policy">Privacy Policy</a>
            <a style={{color: "black",  textDecoration: "unset"}} href="https://anonybit.io/developer">Contact Us</a>
        </div>
      </div>
  </div>;
};