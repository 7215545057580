export const AuthenticationMessage = {
    AUTHENTICATION_SUCCESS: "User Authenticated Successfully",
    AUTHENTICATION_FAILED: "Authentication Failed",
    AUTHENTICATION_TIMEOUT: "Authentication Timeout",
    ENROLLMENT_SUCCESS: "User Enrolled Successfully",
    ENROLLMENT_FAILED: "Enrollment Failed",
    ENROLLMENT_TIMEOUT: "Enrollment Timeout",
}

export const ValidationMessage = {
    INVALID_USERID: "* user identifier length must be between 4 to 30 characters",
    INVALID_EMAIL: "* invalid email",
    INVALID_PHONE_NUMBER: "* invalid phone number"
}