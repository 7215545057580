// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".footer_footer__XXj-R {\n  position: fixed;\n   left: 0;\n   bottom: 0;\n   width: 100%;\n   color: white;\n  /*width: 100%;*/\n  /*height: 100%;*/\n  /*padding-top: 10px;*/\n  /*position: fixed;*/\n  /*margin: 0;*/\n  /*bottom: 0;*/\n}", "",{"version":3,"sources":["webpack://./src/components/footer/footer.module.css"],"names":[],"mappings":"AAAA;EACE,eAAe;GACd,OAAO;GACP,SAAS;GACT,WAAW;GACX,YAAY;EACb,eAAe;EACf,gBAAgB;EAChB,qBAAqB;EACrB,mBAAmB;EACnB,aAAa;EACb,aAAa;AACf","sourcesContent":[".footer {\n  position: fixed;\n   left: 0;\n   bottom: 0;\n   width: 100%;\n   color: white;\n  /*width: 100%;*/\n  /*height: 100%;*/\n  /*padding-top: 10px;*/\n  /*position: fixed;*/\n  /*margin: 0;*/\n  /*bottom: 0;*/\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"footer": "footer_footer__XXj-R"
};
export default ___CSS_LOADER_EXPORT___;
