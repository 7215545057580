// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".no-page_no-page__D7zSF {\n\t\n}", "",{"version":3,"sources":["webpack://./src/pages/no-page/no-page.module.css"],"names":[],"mappings":"AAAA;;AAEA","sourcesContent":[".no-page {\n\t\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"no-page": "no-page_no-page__D7zSF"
};
export default ___CSS_LOADER_EXPORT___;
