import "./home.module.css";
import {useState} from "react";
import { useNavigate } from 'react-router-dom'
import phone from "../../images/phone.svg";
import face from "../../images/face.svg";
import whiteFace from "../../images/face_white.png";
import whitePhone from "../../images/phonewhite.png";
import lock from "../../images/lock-white.png";
import orangeLock from "../../images/lock-orange.png";
import LoginOption from "../../components/login-option/login-option";
export const Home = (props) => {
    const navigate = useNavigate();
    const [whiteImage, setWhiteImage] = useState("");

    const logout = async () => {
        await fetch("/logout")
        window.location.reload()
    }

    return <div className="home">
        <h1 className="text-center" style={{marginTop: 30}}>Anonybit Call Center Authentication</h1>
        <div className="container offset-1" style={{marginTop: 150}}>
            <div
              className="bar ms-lg-5 ps-lg-5 ms-md-0 ps-md-3 pr-0 me-0 "
              onClick={() => {
                setWhiteImage("");
                navigate('/enroll')
              }}
              onMouseOver={() => setWhiteImage("phone")}
              onMouseOut={() => setWhiteImage("")}
            >
              <LoginOption
                img={whiteImage === "phone" ? whitePhone : phone}
                text="Enroll"
              />
            </div>
            <div
              className="bar ms-lg-5 ps-lg-5 ms-md-0 ps-md-3 pr-0 me-0"
              onClick={() => {
                setWhiteImage("");
                navigate('/authenticate')
              }}
              onMouseOver={() => setWhiteImage("face")}
              onMouseOut={() => setWhiteImage("")}
            >
              <LoginOption
                img={whiteImage === "face" ? whiteFace : face}
                text="Authenticate"
              />
            </div>
            <div
                className="bar ms-lg-5 ps-lg-5 ms-md-0 ps-md-3 pr-0 me-0"
                onClick={async () => {
                  await logout();
                }}
                onMouseOver={() => setWhiteImage("lock")}
                onMouseOut={() => setWhiteImage("")}
              >
                <LoginOption
                  img={whiteImage === "lock" ? lock : orangeLock}
                  text="Logout"
                />
              </div>
        </div>
    </div>;
};