import { createServer } from 'miragejs';
import {randomChoice} from "./utils/random";
export function makeServer({ environment = 'test' } = {}) {
  let server = createServer({
    environment,

    routes() {
      this.timing = 400;
      this.namespace = '';
      this.get('/results/:action/:userID', (schema, request) => {
        let responses
        if (request.params.action === "AUTHENTICATE")
          responses = [{"status": "failure", "errorMessage": "unknown user"}, {"status": "success"}, {"status": "failure"}]
        else { // "ENROLL
          // responses = [{"status": "failure"}]
          responses = [{"status": "success"}, {"status": "failure"}]
        }
        return randomChoice(responses)
      }, {timing: 3000});
      this.post('/results/mock',  (schema, request) => {
        return {"status": "success"};
      }, {timing: 1000});
      this.post('/authenticate', (schema, request) => {
        // return {"status": "failure", "errorMessage": "unknown user"}
        return {"status": "success"}
      });
      this.post('/enroll', (schema, request) => {
        // let attrs = JSON.parse(request.requestBody);
        const responses = [{"status": "success"}, {"status": "failure", "errorMessage": "user already exist!"}]
        return randomChoice(responses)
      });
      this.get("configurations", (schema, request) => {
        return {
          debug: true,
          maxTimeoutMinutes: 10,
          pullingIntervalMS: 500,
          mockTimeout: 1000
        }
      })
    },
  });
  return server;
}