import React, { useState } from "react";
import "./authenticate.module.css";
import back from "../../images/back_button.svg";
import { useNavigate } from "react-router-dom";
import { Modal } from "react-bootstrap";
import { AuthenticationMessage } from "../../messages";
import { RingSpinnerOverlay } from "react-spinner-overlay";
import { demosApi } from "../../utils/axios"; // Import your Axios API
import {generateRandomSID} from "../../utils/random";

export const Authenticate = (props) => {
  const navigate = useNavigate();
  const [authenticate, setAuthenticate] = useState({
    userID: "",
    email: "",
    phoneNumber: "",
    method: "email", // Initialize method to 'email' by default
  });
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [modalTextColor, setModalTextColor] = useState("green");
  const [errorMessage, setErrorMessage] = useState("");
  const [modalMessage, setModalMessage] = useState("");

  const authenticateUser = async () => {
    if (!authenticate.userID) return;
    setLoading(true);
    try {
      const requestObject = {
        uid: authenticate.email,
        act: "auth", // Set the action to "authenticate"
        method: authenticate.method,
        sid: generateRandomSID(),
        cid: "dev",
      };
      const response = await demosApi.invite(requestObject); // Use Axios API with the authenticate object
      if (response.success === true) {
        setModalMessage(AuthenticationMessage.AUTHENTICATION_SUCCESS);
        setErrorMessage("");
        setModalTextColor("green");
        setShowModal(true);
      } else {
        setModalMessage(AuthenticationMessage.AUTHENTICATION_FAILED);
        if (response.errorMessage) {
          setErrorMessage(response.errorMessage);
        } else {
          setErrorMessage("");
        }
        setModalTextColor("red");
        setShowModal(true);
      }
    } catch (error) {
      // Handle network or other errors
      console.error("Error:", error);
      setModalMessage("API Error");
      setModalTextColor("red");
      setShowModal(true);
    } finally {
      setLoading(false);
    }
  };

  const handleClose = () => setShowModal(false);

  return (
    <div className="authenticate">
      <div className="container-fluid pe-0 ps-0">
        <div className="row align-items-start flex-direction-column justify-content-center mobile_screen" style={{ height: "100%" }}>
          <div className="mt-md-5  d-flex align-items-center justify-content-center">
            <img className="ms-md-2 pointer" src={back} alt="back_button" onClick={() => { navigate('/') }} />
            <h1 className="h1 ms-md-2 ">Authenticate User to Anonybit</h1>
          </div>
          <Modal aria-labelledby="example-modal-sizes-title-sm" centered show={showModal} onHide={handleClose}>
            <Modal.Header closeButton >
              <Modal.Title><div className="text-center"><i style={{ color: "orange" }} className="fa fa-info-circle fa-3x"></i></div></Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <br /><br />
              <h2 style={{ color: modalTextColor }} className="text-center">{modalMessage}</h2>
              <h3 className="text-center">{errorMessage}</h3>
              <br /><br />
            </Modal.Body>
          </Modal>
          <div className="container form-wrapper">
            <div className="row offset-2">
              <label htmlFor="userIDTbx">Username</label>
              <input id="userIDTbx" type="text" placeholder="user ID" className="phone form-control w-75" value={authenticate.userID} onChange={(e) => setAuthenticate({ ...authenticate, userID: e.currentTarget.value })} />
            </div>
            <br />

            <div className="row offset-2">
              <label htmlFor="emailTbx">User Email Address</label>
              <input id="emailTbx" placeholder="email" type="text" className="phone form-control w-75" value={authenticate.email} onChange={(e) => setAuthenticate({ ...authenticate, email: e.currentTarget.value })} />
            </div>

            <br />

            <div className="row offset-2 ">
              <label htmlFor="phoneNumberTbx">User Phone Number</label>
              <input id="phoneNumberTbx" placeholder="phone number" type="text" className="phone form-control w-75" value={authenticate.phoneNumber} onChange={(e) => setAuthenticate({ ...authenticate, phoneNumber: e.currentTarget.value })} />
            </div>

            <br />
            {/*<div className="row offset-2">*/}
            {/*  <label htmlFor="methodRadio">Communication Method</label>*/}
            {/*  <div className="radio-buttons">*/}
            {/*    <label>*/}
            {/*      <input*/}
            {/*        type="radio"*/}
            {/*        name="methodRadio"*/}
            {/*        value="sms"*/}
            {/*        checked={authenticate.method === "sms"}*/}
            {/*        onChange={(e) => setAuthenticate({ ...authenticate, method: e.target.value })}*/}
            {/*      />*/}
            {/*      SMS*/}
            {/*    </label>*/}
            {/*    <label>*/}
            {/*      <input*/}
            {/*        type="radio"*/}
            {/*        name="methodRadio"*/}
            {/*        value="email"*/}
            {/*        checked={authenticate.method === "email"}*/}
            {/*        onChange={(e) => setAuthenticate({ ...authenticate, method: e.target.value })}*/}
            {/*      />*/}
            {/*      Email*/}
            {/*    </label>*/}
            {/*  </div>*/}
            {/*</div>*/}
            <RingSpinnerOverlay loading={loading} size={100} color="orange" />
            <div className="offset-9 pt-5">
              <button className="btn" disabled={!authenticate.userID || loading} onClick={authenticateUser}>
                Authenticate
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-right" viewBox="0 0 16 16">
                  <path fillRule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z" />
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
