export const randomChoice = (sequence) => {
    const randomIndex = randInt(0, sequence.length - 1)
    return sequence[randomIndex]
}

export const randInt = (min, max) => {
  return Math.floor(Math.random() * (max - min + 1) ) + min;
}

export const generateRandomSID = () => {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const sidLength = 12; // You can adjust the length as needed
  let sid = '';

  for (let i = 0; i < sidLength; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    sid += characters.charAt(randomIndex);
  }

  return sid;
}